import React, { FC } from 'react'
import { graphql } from 'gatsby'
import type { CategoryInterface } from '../../graphql/magento'
import { CategoryPage } from '../../components/shop/products/CategoryPage'

interface CategoryPageTemplateProps {
  data: {
    magento: {
      category: CategoryInterface
    }
  }
}

export const query = graphql`
  query CategoryPageTemplateQuery($categoryId: Int!) {
    magento {
      category(id: $categoryId) {
        breadcrumbs {
          category_name
          category_url_key
          category_url_path
        }
        id
        name
        url_path
      }
    }
  }
`

const CategoryPageTemplate: FC<CategoryPageTemplateProps> = ({ data }) => {
  const {
    magento: { category },
  } = data

  return <CategoryPage category={category} />
}

export default CategoryPageTemplate
